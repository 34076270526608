@import url('./assets/fonts/fonts.css');

html,
body {
    margin: 0px;
    background: #f9f9f9;
    height: 100%;
    color: #323A4D;
    font-family: var(--font-family);
    font-weight: 100;
}

label {
    margin: 10px 0px;
    display: inline-block;
    margin-top: 0px;
}

.w-100 {
    width: 100%;
}

.float-label {
    input:-webkit-autofill + label {
        top: -0.75rem;
        font-size: 18px !important;
    }
}

.icon {
    background-size: contain;
    height: 20px;
    width: 20px;
    background-repeat: no-repeat;
    display: inline-block;
    background-position: center center;

    position: relative;
    bottom: -4px;
    margin-right: 14px;

    &.icon-menu {
        background-image: url('./assets/images/icons/menu.svg');
    }

    &.icon-plus {
        background-image: url('./assets/images/icons/plus.svg');
    }

    &.icon-edit {
        background-image: url('./assets/images/icons/edit.svg');
    }

    &.icon-radio-selected {
        background-image: url('./assets/images/icons/radio-selected.svg');
    }

    &.icon-radio-unselected {
        background-image: url('./assets/images/icons/radio-unselected.svg');
    }


    &.icon-completed {
        background-image: url('./assets/images/icons/completed.svg');
    }

    &.icon-failed {
        background-image: url('./assets/images/icons/failed.svg');
    }

    &.icon-processing {
        background-image: url('./assets/images/icons/processing.svg');
    }

    &.icon-expires {
        background-image: url('./assets/images/icons/failed.svg');
    }

    &.icon-cancelled {
        background-image: url('./assets/images/icons/cancelled.svg');
    }

    &.icon-scheduled {
        background-image: url('./assets/images/icons/scheduled.svg');
    }

    &.icon-preprocessing {
        background-image: url('./assets/images/icons/preprocessing.svg');
    }

    &.icon-download {
        background-image: url('./assets/images/icons/download.svg');
    }

    &.icon-upload {
        background-image: url('./assets/images/icons/upload.svg');
    }

    &.icon-remove {
        background-image: url('./assets/images/icons/remove.svg');
    }

    &.icon-check {
        background-image: url('./assets/images/icons/check.svg');
    }

    &.icon-back {
        background-image: url('./assets/images/icons/back.svg');
    }

    &.hover {
        opacity: 0.9;
    }

    &.disabled {
        opacity: 0.5;
    }

    &.icon-invert {
        filter: brightness(0) invert(1);
    }

    &.icon-m-zero {
        margin: 0px;
    }
}

input:-webkit-autofill {
    font-size: 13px;
    padding: 16px 25px;
    outline: 0 none;
    outline-offset: 0;
    font-weight: normal;
    line-height: 15.6px;
    background-color: transparent;

    &::first-line {
        font-family: 'Museo Sans', Inter, ui-sans-serif, system-ui,
            -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
            'Helvetica Neue', Arial, 'Noto Sans', sans-serif,
            'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
            'Noto Color Emoji';
    }
}

.input-wrapper {
    margin-top: 25px;
    margin-bottom: 10px;

    .input-text-wrapper {
        label {
            color: #323a4d;
            font-weight: 100;
        }
    }

    .p-field-radiobutton {
        display: flex;

        label {
            margin: 5px 10px;
        }
    }
}

.error-message {
    color: var(--error-color);
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    line-height: 16px;
    margin-top: 15px;
}

.custom-upload-design {
    position: relative;

    .progress-bar {
        position: relative;
        display: block;
        width: 300px;
        margin: 0 auto;
    }

    .p-fileupload-content {
        padding: 57px 38px;
        min-height: 400px;

        p-messages {
            display: none;
        }

        .upload-info,
        .upload-info-hasfile {
            font-style: normal;
            font-weight: 300;
            font-size: 18px;
            line-height: 22px;

            .icon {
                width: 48px;
                height: 44.36px;
            }

            .pi-file {
                font-size: 30px;
            }
        }

        .upload-info-hasfile {
            .pi-file {
                margin-top: 40px;
            }

            .btn {
                cursor: pointer;

                i {
                    width: 20px;
                    height: 20px;
                }

                font-style: normal;
                font-weight: 300;
                font-size: 16px;
                line-height: 19px;
                position: absolute;
                left: 0;
                right: 0;
                bottom: 10px;
                bottom: 32px;
            }

            .plr-50 {
                padding-left: 50px;
                padding-right: 50px;
            }
        }

        .file-info {
            position: absolute;
            bottom: 40px;
            text-align: center;
            left: 0;
            right: 0;
        }
    }

    &.hide-choose {
        .p-fileupload-buttonbar,
        .p-fileupload-files,
        .p-progressbar {
            display: none !important;
        }
    }

    &.loading {
        .p-fileupload-buttonbar,
        .p-fileupload-files {
            display: none !important;
        }

        .p-progressbar {
            display: block !important;
        }

        .p-progressbar .p-progressbar-value {
            border: 0 none;
            margin: 0;
        }
    }

    .p-fileupload .p-fileupload-buttonbar {
        position: absolute;
        z-index: 1;
        display: inline-block;
        padding: 0;
        width: 100%;
        width: 283px;
        margin: auto;
        background-color: transparent;
        border-color: transparent;
        left: 0;
        right: 0;
        bottom: 132px;

        span.p-button {
            margin: 0;
            width: 100%;
        }
    }

    .p-fileupload .p-fileupload-content {
        min-height: 400px;
        border: 1.3px dashed rgb(50, 58, 77, 0.4);
        gap: 35px;

        &.p-fileupload-highlight {
            position: relative;

            &:before {
                content: ' ';
                position: absolute;
                height: 100%;
                width: 100%;
                top: 0;
                left: 0;
                background-color: #ffffffbf;
                z-index: 1;
            }
        }
    }
}
